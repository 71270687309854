
export interface StdProposalCopyToEntity {
    
    tariffType: string,
    chargePercentage: number,
    roundMethod: string,
    decimalPlace: string,

    [key: string]: string | boolean | number | Date | null | undefined | Object
}

export const EMPTY_STD_PROPOSAL_COPY_TO_ENTITY: StdProposalCopyToEntity = {
    tariffType: "",
    chargePercentage: 100,
    roundMethod: "ROUND",
    decimalPlace: "2"
}