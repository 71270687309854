import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { StandardProposalEntity } from "domain/entity/StandardProposal/StandardProposalEntity";
import { StandardProposalSearchCriteria } from "domain/entity/StandardProposal/StandardProposalSearchCriteria";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { StandardProposalRepository } from "./StandardProposalRepo";

export const StandardProposalRepoImpl = (): StandardProposalRepository => {
    
    const searchUrl = "/v1/standardProposalSearch"
    const deleteUrl = "/v1/standardProposalDelete"
    const submitUrl = "/v1/standardProposalSubmit"
    const unconfirmUrl = "/v1/standardProposalUnconfirm"
    const rejectUrl = "/v1/standardProposalReject"
    const approveUrl = "/v1/standardProposalApprove"
    const saveUrl = "/v1/standardProposalSave"
    const renewUrl = "/v1/standardProposalRenew"

    const searchStandardProposal = async(searchCriteria: StandardProposalSearchCriteria) : Promise<StandardProposalEntity[]> => {
        
        return axiosPostData(tariffAxiosInstance,`${searchUrl}`, searchCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const deleteSTDProposal = async(keys: string[]) : Promise<string> => {
        
        return axiosPostData(tariffAxiosInstance,`${deleteUrl}`, keys).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const submitStandardProposal = async(selectedRows: StandardProposalEntity[]) : Promise<string> => {
        
        return axiosPostData(tariffAxiosInstance,`${submitUrl}`, selectedRows).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const unconfirmStandardProposal = async(selectedRows: StandardProposalEntity[]) : Promise<string> => {
        
        return axiosPostData(tariffAxiosInstance,`${unconfirmUrl}`, selectedRows).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const approveStandardProposal = async(selectedRows: StandardProposalEntity[]) : Promise<string> => {
        
        return axiosPostData(tariffAxiosInstance,`${approveUrl}`, selectedRows).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const onRejectStandardProposal = async(selectedRow: StandardProposalEntity) : Promise<string> => {
        
        return axiosPostData(tariffAxiosInstance,`${rejectUrl}`, selectedRow).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const saveStandardProposal = async(entity: StandardProposalEntity) : Promise<ResponseEntity> => {
        
        // return axiosPostData(tariffAxiosInstance,`${saveUrl}`, entity).then(res => {
        //     const data = res.data;
        //     return data;
        // }).catch(err => {
        //     return [];
        // });

        try {
            return await axiosPostData(tariffAxiosInstance,`${saveUrl}`, entity) as ResponseEntity;            
        } catch (err) {
            throw err;
        }
    }

    const renewStandardProposal = async(keys: number[]) : Promise<string> => {
        
        return axiosPostData(tariffAxiosInstance,`${renewUrl}`, keys).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    return {
        searchStandardProposal : searchStandardProposal,
        deleteSTDProposal : deleteSTDProposal,
        submitStandardProposal : submitStandardProposal,
        unconfirmStandardProposal : unconfirmStandardProposal,
        onRejectStandardProposal : onRejectStandardProposal,
        approveStandardProposal : approveStandardProposal,
        saveStandardProposal : saveStandardProposal,
        renewStandardProposal : renewStandardProposal,
    }
}
