import { EMPTY_STANDARD_PROPOSAL_DETAIL_MODEL } from "presentation/model/StandardProposal/StandardProposalDetailModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: StandardProposalDetailProvider,
    useTracked: useStandardProposalDetailTracked
} = createContainer(() => useState(EMPTY_STANDARD_PROPOSAL_DETAIL_MODEL), {concurrentMode: true});
export { StandardProposalDetailProvider, useStandardProposalDetailTracked };

