import { ProposalEntity } from "./ProposalEntity"

export interface StandardProposalEntity extends ProposalEntity {
    
    key: string,
    companyCode?: string | null,
    proposalNo?: string | null,
    proposalCreatedByMethod?: string | null,
    tariffNature?: string | null,
    tariffNatureList?: string[] | null,

    [key: string]: string | boolean | number | Date | null | undefined | Object
}

export const EMPTY_STANDARD_PROPOSAL_ENTITY: StandardProposalEntity = {

    key: "",
    companyCode: "",
    proposalNo: null,
    operatingTml: "",
    effectiveDate: null,
    expiryDate: null,
    currencyCode: "",
    proposalCreatedByMethod: "",
    tariffNature: "",
    tariffNatureList: [],
    confirmedDate: null,
    status: 'NEW',
    rejectReason: null,
    remarks: "",
}