export interface StdProposalTariffItemTierEntity {

	key?: string | null,
    tierNo?: number | null,
	cycle?: number | null,
	forevery?: number | null,
	uom?: string | null,
	rate: number | null,
	minimum?: number | null,
	surcharge?: number | null,
	surchargeType?: string | null,
	additionalUom?: string | null,
	additionalRate?: number | null,
	newRecord?: boolean | null,

    [key: string]: string | boolean | number | Date | null | undefined | Object
}

export const EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY: StdProposalTariffItemTierEntity = {

    key: null,
    tierNo: null,
	cycle: null,
	forevery: null,
	uom: null,
	rate: null,
	minimum: null,
	surcharge: null,
	surchargeType: null,
	additionalUom: null,
	additionalRate: null,
	newRecord: false,
}