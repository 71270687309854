
export interface StdProposalCopyToProposalEntity {
    
    companyCode: string,
    proposalNo: string,

}

export const EMPTY_STD_PROPOSAL_COPY_TO_PROPOSAL_ENTITY: StdProposalCopyToProposalEntity = {
    companyCode: "",
    proposalNo: ""
}