import { ExchangeRateRepoImpl } from "domain/repository/ExchangeRate/ExchangeRateRepoImpl";
import { StandardProposalItemRepoImpl } from "domain/repository/StandardProposal/StandardProposalItemRepoImpl";
import { StandardProposalRepoImpl } from "domain/repository/StandardProposal/StandardProposalRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TariffNatureRepoImpl } from "domain/repository/TariffNature/TariffNatureRepoImpl";
import { useStandardProposalDetailTracked } from "presentation/store/StandardProposal/StandardProposalDetailProvider";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { StandardProposalDetailVM } from "presentation/viewModel/StandardProposal/StandardProposalDetailVM";
import { useMemo } from "react";

export const useStandardProposalDetailVM = () => {
    const [, setStandardProposalDetailState] = useStandardProposalDetailTracked();
    const [, setStandardProposalState] = useStandardProposalTracked();
    const standardProposalDetailVM = useMemo(() =>
        StandardProposalDetailVM({
            itemDispatch: [setStandardProposalDetailState],
            dispatch: [setStandardProposalState],
            standardProposalItemRepo: StandardProposalItemRepoImpl(),
            exchangeRateRepo: ExchangeRateRepoImpl(),
            tariffNatureRepo: TariffNatureRepoImpl(),
            standardTariffCodeRepo:StandardTariffCodeRepoImpl(),
            standardProposalRepo: StandardProposalRepoImpl(),
        }), [setStandardProposalDetailState, setStandardProposalState])

    return standardProposalDetailVM
}