import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useStandardProposalDetailVM } from "presentation/hook/StandardProposal/useStandardProposalDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalDetailTracked } from "presentation/store/StandardProposal/StandardProposalDetailProvider";
import { memo, useCallback } from "react";
import { HPHButton, SliderPanel } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import StandardProposalDetailTablePanel from "../Table/StandardProposalDetailTablePanel";
import AddTariffItemTierTablePanel from "./AddTariffItemTierTablePanel";

const AddTariffItemRightPanel = () => {
    const [standardProposalDtlState] = useStandardProposalDetailTracked();
    const { isShowAddTariffItemPanel,currentSelectItem,isAddTariffItem,standardProposalItemList,isAddIs,isEditIs,isEditTier,isAddTier } = standardProposalDtlState;
    const standardProposalDtlVM = useStandardProposalDetailVM();
    const messageBarVM = useMessageBarVM();

    const isDisable = () => {
        if(isAddTariffItem&&!(isAddIs||isEditIs||isEditTier||isAddTier)) return true
        return false;
    }

    const handleSave = useCallback(async () => {
        if(isAddTariffItem){
            if(!currentSelectItem.tariffType){
                messageBarVM.showError("Tariff Type is mandatory.");
                return;
            }
            if(!currentSelectItem.tariffCode){
                messageBarVM.showError("Tariff Code is mandatory.");
                return;
            }
        }
        if(standardProposalItemList && standardProposalItemList.length > 0){
            if (standardProposalItemList.some(item => 
                item.tariffType === currentSelectItem.tariffType &&
                item.tariffCode === currentSelectItem.tariffCode &&
                item.forwarderCode === currentSelectItem.forwarderCode
              )) {
                messageBarVM.showError("Duplicate record found, please check the key: Tariff Type, Tariff Code, Forwarder Code.");
                return;
              }
        }
        if(currentSelectItem.tierList && currentSelectItem.tierList.length === 0){
            messageBarVM.showError("Must add one tariff item tier at least.");
            return;
        }
        
        if(isAddTariffItem && currentSelectItem.tierList && currentSelectItem.tierList.length > 0){
            standardProposalDtlVM.onTempSaveTariffItem(standardProposalItemList, currentSelectItem);
        }
        
    }, [currentSelectItem, isAddTariffItem, messageBarVM, standardProposalDtlVM, standardProposalItemList]);



    return <>
        <div className={`main-comp-wrapper${(isShowAddTariffItemPanel) ? '' : ' im-hide-side-form-draggable'}`}>
            <div style={{width: '100%', height: '100%', maxHeight: '85%'}}>
                <SliderPanel
                    isOpen={true}
                    draggable={true}
                    leftSectionWidth={isShowAddTariffItemPanel?"50%":"100%"}
                    rightSectionWidth={isShowAddTariffItemPanel?"50%":"0%"}
                    leftChildren={<StandardProposalDetailTablePanel/>}
                    rightChildren={<AddTariffItemTierTablePanel standardProposalDtlState={standardProposalDtlState}/>}
                />
            </div>     
            <div style={{paddingRight: '25px',visibility:isDisable()?'visible':'hidden'}} >
            <SidebarActionBar>
                {<HPHButton disabled={!isDisable()} label={WorkspaceConstant.Common.BUTTON_SAVE} size={'Small'} theme={'Primary'} onClick={handleSave} />}
            </SidebarActionBar>
            </div>
        </div>
    </>
}

export default memo(AddTariffItemRightPanel);