export interface StandardProposalEnabledSearchCriteria {

    all: boolean,
    companyCode: boolean,
    proposalNo: boolean,
    operatingTml: boolean,
    status: boolean,
    effectiveDateRange: boolean,  

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_STANDARD_PROPOSAL_SEARCH_CRITERIA: StandardProposalEnabledSearchCriteria = {
    all: true,
    companyCode: true,
    proposalNo: true,
    operatingTml: true,
    status: true,
    effectiveDateRange: true
}