import { EMPTY_STANDARD_PROPOSAL_MODEL } from "presentation/model/StandardProposal/StandardProposalModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: StandardProposalProvider,
    useTracked: useStandardProposalTracked
} = createContainer(() => useState(EMPTY_STANDARD_PROPOSAL_MODEL), {concurrentMode: true});
export { StandardProposalProvider, useStandardProposalTracked };

