import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { StandardProposalDetailProvider } from "presentation/store/StandardProposal/StandardProposalDetailProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import StandardProposalDetailMaintenance from "presentation/view/section/StandardProposal/Detail/StandardProposalDetailMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const StandardProposalDetailContMain = () => {
    return <ANAInfoWrapper permission={Permission.STANDARD_PROPOSAL_DETAIL_MAINTENANCE}>
        <MessageBarWrapper>
            <StandardProposalDetailProvider>
                <GridStyles/>
                <StandardProposalDetailMaintenance/>
            </StandardProposalDetailProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default StandardProposalDetailContMain;