import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { TariffNatureEntity } from "domain/entity/TariffNature/TariffNatureEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { TariffNatureRepository } from "./TariffNatureRepo";


export const TariffNatureRepoImpl = (): TariffNatureRepository => {
    const url = '/v1/tariffNature';


    const getAllActiveTariffNature = async (): Promise<TariffNatureEntity[]> => {
        const urlCombobox = '/v1/tariffNatureForComboBox';
        return axiosGetData(tariffAxiosInstance, urlCombobox, []).then(res => {
            return _.sortBy(res.data, ["tariffNature"]);
        }).catch(err => {
            return [];
        });
    }


    const getEntities = async() : Promise<TariffNatureEntity[]> => {
        return await axiosGetData(tariffAxiosInstance, url, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });
        
    }

    const getEntityByKey = async(key: string): Promise<TariffNatureEntity> => {
        return await axiosGetData(tariffAxiosInstance, `${url}/${key}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const createEntity = async(newData: TariffNatureEntity) : Promise<ResponseEntity> => {
        return await axiosPostData(tariffAxiosInstance, `${url}`, newData);
    }

    const updateEntity = async(updatedData: TariffNatureEntity): Promise<ResponseEntity> => {
        return await axiosPutData(tariffAxiosInstance, `${url}`, updatedData);
    }

    const deleteEntity= async(key: string): Promise<ResponseEntity> => {
        return await axiosDeleteData(tariffAxiosInstance, `${url}`, key);
    }

    return {
        getAllActiveTariffNature: getAllActiveTariffNature,
        getEntities: getEntities,
        getEntityByKey: getEntityByKey,
        createEntity: createEntity,
        updateEntity: updateEntity,
        deleteEntity: deleteEntity,
    }
}