
export interface StdProposalCopyToNewProposalEntity {
    
    companyCode: string,
    proposalCreatedByMethod: string,
    operatingTml: string | null,
    effectiveDate: Date | null,
    expiryDate: Date | null,
    currencyCode: string,
    remarks: string | null,

}

export const EMPTY_STD_PROPOSAL_COPY_TO_NEW_PROPOSAL_ENTITY: StdProposalCopyToNewProposalEntity = {
    companyCode: "",
    proposalCreatedByMethod: "",
    operatingTml: null,
    currencyCode: "",
    remarks: null,
    effectiveDate: null,
    expiryDate: null
}