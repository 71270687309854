
export interface StandardProposalSearchCriteria{
    companyCodeList?: string[] | null,
    proposalNo?: string | null,
    operatingTmlList?: string[] | null,
    statusList?: string[] | null,
    effectiveDateFrom?: Date | null,
    effectiveDateTo?: Date | null,  
    approvalStatus?: string | null,
    
    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_STANDARD_PROPOSAL_SEARCH_CRITERIA : StandardProposalSearchCriteria = {

    companyCodeList: [],
    proposalNo: null,
    operatingTmlList: [],
    statusList: [],
    effectiveDateFrom: null,   
    effectiveDateTo: null,   
    approvalStatus: null,   
}