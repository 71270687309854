import { StandardProposalConstant } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { useStandardProposalDetailVM } from "presentation/hook/StandardProposal/useStandardProposalDetailVM";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo } from "react";
import { HPHBreadcrumb } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const StandardProposalDetailTitleBar: React.FC = () => {
    // const [standardProposalDtlState] = useStandardProposalDetailTracked();
    const standardProposalDtlVM = useStandardProposalDetailVM();
    // const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Title;
    // const messageBarVM = useMessageBarVM();




    return <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
        <HeaderWithBackButton callback={standardProposalDtlVM.onCloseClick}>
        <Breadcrumb>
        <HPHBreadcrumb breadcrumbData={[{title: StandardProposalConstant.Agreement.AGREEMENT},{ title: StandardProposalConstant.Title.STANDARD_PROPOSAL }]} onCurrentClick={()=>{}}></HPHBreadcrumb>
        </Breadcrumb>
        </HeaderWithBackButton>
        {/* <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} onClick={handleAdd} />
        </StyledAction> */}
    </Sidebarheader>
}

export default memo(StandardProposalDetailTitleBar);